<template>
  <div class="row">
    <div class="table-overlay" v-show="loading">
      <div class="table-overlay-container">
        <material-spinner></material-spinner>
        <span>{{ $t("global.loading") }}</span>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-primary card-header-icon">
          <div class="card-icon">
            <i class="material-icons">assignment</i>
          </div>
          <h4 class="card-title">
            <strong>{{ $t("data.assetAlerts.title") }}</strong>
          </h4>
        </div>
        <div class="card-body">
          <button
            type="button"
            class="btn btn-default"
            @click="fetchIndexData"
            :disabled="loading"
            :class="{ disabled: loading }"
          >
            <i class="material-icons" :class="{ 'fa-spin': loading }">
              refresh
            </i>
            {{ $t("global.refresh") }}
          </button>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <ul>
                <asset-alert-component
                  v-for="(assetAlert, key) in data"
                  :key="key"
                  :data="assetAlert"
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AssetAlertComponent from "./Components/AssetAlertComponent.vue";

export default {
  components: {
    AssetAlertComponent
  },
  data() {
    return {
      query: {
        sort: "id",
        order: "desc",
        limit: 100,
        s: ""
      },
      xprops: {
        module: "AssetAlertsIndex",
        route: "assetAlerts"
      }
    };
  },
  beforeDestroy() {
    this.resetState();
  },
  computed: {
    ...mapGetters("AssetAlertsIndex", ["data", "total", "loading"])
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      handler() {
        this.resetState;
        this.fetchIndexData();
      }
    }
  },
  methods: {
    ...mapActions("AssetAlertsIndex", [
      "fetchIndexData",
      "setQuery",
      "resetState"
    ])
  }
};
</script>
<template>
  <router-link :to="{ name: 'assets.show', params: { id: data.asset.id } }">
    <li class="container">
      <div class="row">
        <div class="col-md-1 align-self-center align-content-center">
          <div class="icon">
            <i class="material-icons">warning</i>
          </div>
        </div>
        <div class="col-md-11 align-content-center pt-3">
          <div class="container">
            <div class="row title">
              <strong>{{
                $t(`data.assetAlerts.fields.type.${data.type}`)
              }}</strong>
            </div>

            <div class="row detail">
              <p>
                Orario: {{ data.alert_ts }} <br />
                Nome bovino: {{ data.asset.name }} <br />
                Nome mandria: {{ data.asset.herd.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </li>
  </router-link>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        alert_ts: "",
        asset: {},
        id: "",
        type: ""
      })
    }
  }
};
</script>

<style scoped lang="scss">
li {
  list-style-type: none;
}
li:hover {
  background: linear-gradient(
    130deg,
    rgba($sidebar-color1, 0.3) 11%,
    rgba($sidebar-color2, 0.3) 77%,
  );
}
.dataContainer {
  display: flex;
  align-content: center;
  flex-direction: row;
}
.textContainer {
  display: flex;
  flex-direction: column;
}
.icon {
  color: $color-orange;
  margin: 1em;
}
.title {
  font-weight: 900;
  font-size: 1.5em;
}
</style>